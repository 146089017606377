import { useState } from 'react'

export const useImagesLightbox = () => {
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState([])

  const openLightboxAndSetImages = (imagesToLoad) => {
    setOpen(true)
    setImages(imagesToLoad)
  }

  return {
    open,
    setOpen,
    images,
    openLightboxAndSetImages,
  }
}
