import { HashRouter, Routes, Route, Link } from 'react-router-dom'

import { ContactUs } from '@srcroot/pages/contactUs'
import { Home } from '@srcroot/pages/home'
import { Products } from '@srcroot/pages/products'

import { BeefRendang } from '@srcroot/pages/products/beef-rendang'
import { ChickenKari } from '@srcroot/pages/products/chicken-kari'
import { NyonyaChicken } from '@srcroot/pages/products/nyonya-chicken'
import { LaksaMee } from '@srcroot/pages/products/laksa-mee'
import { FishKari } from '@srcroot/pages/products/fish-kari'
import { SpiceChickenHerbs } from '@srcroot/pages/products/spice-chicken-herbs'
import { SataySauce } from '@srcroot/pages/products/satay-sauce'
import { GreenKari } from '@srcroot/pages/products/green-kari'
import { LambKari } from '@srcroot/pages/products/lamb-kari'
import { SpiceChickenCoconut } from '@srcroot/pages/products/spice-chicken-coconut'

export const NavigationRouter = () => {
  return (
    <>
      <HashRouter>
        <div className="flex my-2 text-xl">
          <Link to="" className="px-4 py-4 hover:bg-gray-500">
            Home
          </Link>
          <Link to="products" className="px-4 py-4 hover:bg-gray-500">
            Products
          </Link>
          <Link to="contactUs" className="px-4 py-4 hover:bg-gray-500">
            Contact Us
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="products" element={<Products />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="products/beefRendang" element={<BeefRendang />} />
          <Route path="products/laksaMee" element={<LaksaMee />} />
          <Route path="products/fishKari" element={<FishKari />} />
          <Route path="products/spiceChickenHerbs" element={<SpiceChickenHerbs />} />
          <Route path="products/chickenKari" element={<ChickenKari />} />
          <Route path="products/sataySauce" element={<SataySauce />} />
          <Route path="products/greenKari" element={<GreenKari />} />
          <Route path="products/lambKari" element={<LambKari />} />
          <Route path="products/nyonyaChicken" element={<NyonyaChicken />} />
          <Route path="products/spiceChickenCoconut" element={<SpiceChickenCoconut />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </HashRouter>
    </>
  )
}
