export const OurStory = () => {
  return (
    <>
      <h1 className="uppercase">Our Story</h1>
      <p>
        It can be difficult to prepare and cook a wholesome meal at home. Hard pressed for time and
        the constant struggle of answering &quot;What&apos;s for dinner?&quot;. It&apos;s then I
        feel so nostalgic for mother&apos;s cooking.
      </p>
      <p>
        Delicious, fresh and authentic dishes. We have recreated all these qualities into blended
        ingredients and sauces. Using local fresh ingredients and imported spices, you can cook and
        eat with confidence!
      </p>
      <p className="text-center">
        Malaysian curry paste | satay sauce | chilli sauce | spice whole
      </p>
    </>
  )
}
