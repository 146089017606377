import BEEF_RENDANG_MEAL_KIT_IMAGE from '@srcroot/assets/beef-rendang/beef-rendangr2.png'
import LAKSA_MEE_MEAL_KIT_IMAGE from '@srcroot/assets/laksa-mee/laksa-meer2.png'
import FISH_KARI_MEAL_KIT_IMAGE from '@srcroot/assets/fish-kari/fish-karir2.png'

import SPICE_CHICKEN_HERBS_MEAL_KIT_IMAGE from '@srcroot/assets/spice-chicken-herbs/roasted-spiced-chicken-herbsr2.png'
import CHICKEN_KARI_MEAL_KIT_IMAGE from '@srcroot/assets/chicken-kari/Chicken-karir2.png'
import SATAY_SAUCE_MEAL_KIT_IMAGE from '@srcroot/assets/satay-sauce/satay-saucer3.png'

import GREEN_KARI_MEAL_KIT_IMAGE from '@srcroot/assets/green-kari/green-karir3.png'
import LAMB_KARI_MEAL_KIT_IMAGE from '@srcroot/assets/lamb-kari/lamb-karir2.png'
import NYONYA_CHICKEN_MEAL_KIT_IMAGE from '@srcroot/assets/nyonya-chicken/nyonya-chickenr2.png'

import SPICE_CHICKEN_COCONUT_MEAL_KIT_IMAGE from '@srcroot/assets/spice-chicken-coconut/roasted-spiced-chicken-coconutr2.png'
import WHITE_IMAGE from '@srcroot/assets/white.png'

import { Link } from 'react-router-dom'
import { Footer } from '@srcroot/components/footer'

export const Products = () => {
  return (
    <>
      <h1 data-testid="product-header" className="uppercase">
        Products
      </h1>
      <h1 className="uppercase text-center text-gray-500">Meal Kits</h1>

      <div className="flex justify-evenly">
        <Link to="beefRendang">
          <img
            data-testid="product-beef-rendang-meal-kit-image"
            src={BEEF_RENDANG_MEAL_KIT_IMAGE}
            alt="beef rendang meal kit"
          />
          <p className="text-center">beef rendang $8.20</p>
        </Link>
        <Link to="laksaMee">
          <img
            data-testid="product-laksa-mee-meal-kit-image"
            src={LAKSA_MEE_MEAL_KIT_IMAGE}
            alt="laksa mee meal kit"
          />
          <p className="text-center">laksa mee $8.20</p>
        </Link>
        <Link to="fishKari">
          <img
            data-testid="product-fish-kari-meal-kit-image"
            src={FISH_KARI_MEAL_KIT_IMAGE}
            alt="fish kari meal kit"
          />
          <p className="text-center">fish kari $6.00</p>
        </Link>
      </div>

      <div className="flex justify-evenly">
        <Link to="spiceChickenHerbs">
          <img
            data-testid="product-spice-chicken-herbs-meal-kit-image"
            src={SPICE_CHICKEN_HERBS_MEAL_KIT_IMAGE}
            alt="spice chicken herbs meal kit"
          />
        </Link>
        <Link to="chickenKari">
          <img
            data-testid="product-chicken-kari-meal-kit-image"
            src={CHICKEN_KARI_MEAL_KIT_IMAGE}
            alt="chicken kari meal kit"
          />
        </Link>
        <Link to="sataySauce">
          <img
            data-testid="product-satay-sauce-meal-kit-image"
            src={SATAY_SAUCE_MEAL_KIT_IMAGE}
            alt="satay sauce meal kit"
          />
        </Link>
      </div>
      <div className="flex justify-evenly">
        <Link to="spiceChickenHerbs">
          <p className="text-center">spice chicken herbs $6.00</p>
        </Link>
        <Link to="chickenKari">
          <p className="text-center">chicken kari $6.00</p>
        </Link>
        <Link to="sataySauce">
          <p className="text-center">satay sauce $8.20</p>
        </Link>
      </div>

      <div className="flex justify-evenly">
        <Link to="greenKari">
          <img
            data-testid="product-green-kari-meal-kit-image"
            src={GREEN_KARI_MEAL_KIT_IMAGE}
            alt="green kari meal kit"
          />
        </Link>
        <Link to="lambKari">
          <img
            data-testid="product-lamb-kari-meal-kit-image"
            src={LAMB_KARI_MEAL_KIT_IMAGE}
            alt="lamb kari meal kit"
          />
        </Link>
        <Link to="nyonyaChicken">
          <img
            data-testid="product-nyonya-chicken-meal-kit-image"
            src={NYONYA_CHICKEN_MEAL_KIT_IMAGE}
            alt="nyonya chicken meal kit"
          />
        </Link>
      </div>
      <div className="flex justify-evenly">
        <Link to="greenKari">
          <p className="text-center">green kari $6.00</p>
        </Link>
        <Link to="lambKari">
          <p className="text-center">lamb kari $6.00</p>
        </Link>
        <Link to="nyonyaChicken">
          <p className="text-center">nyonya chicken $6.00</p>
        </Link>
      </div>

      <div className="flex justify-evenly">
        <div>
          {/* Add placholders to space single image properly aligned with other images */}
          <img className="mx-auto" src={WHITE_IMAGE} alt="white placeholder" />
          <p className="text-center text-white">spice chicken coconut $6.00</p>
        </div>
        <Link to="spiceChickenCoconut">
          <img
            className="mx-auto"
            data-testid="product-spice-chicken-coconut-meal-kit-image"
            src={SPICE_CHICKEN_COCONUT_MEAL_KIT_IMAGE}
            alt="roasted spice chicken coconut meal kit"
          />
          <p className="text-center">spice chicken coconut $6.00</p>
        </Link>
        <div>
          {/* Add placholders to space single image properly aligned with other images */}
          <img className="mx-auto" src={WHITE_IMAGE} alt="white placeholder" />
          <p className="text-center text-white">spice chicken coconut $6.00</p>
        </div>
      </div>

      <Footer />
    </>
  )
}
