import EMAIL_IMAGE from '@srcroot/assets/email.png'
import FACEBOOK_IMAGE from '@srcroot/assets/facebook.png'
import INSTAGRAM_IMAGE from '@srcroot/assets/instagram.png'

export const Footer = () => {
  return (
    <>
      <footer data-testid="footer" className="mb-6">
        <div className="flex justify-center">
          <a
            data-testid="footer-email-link"
            href="mailto:treeaprons@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <img data-testid="footer-email-image" src={EMAIL_IMAGE} alt="email" />
          </a>
          <a
            data-testid="footer-facebook-link"
            href="https://www.facebook.com/tree.aprons.9"
            target="_blank"
            rel="noreferrer"
          >
            <img data-testid="footer-facebook-image" src={FACEBOOK_IMAGE} alt="facebook" />
          </a>
          <a
            data-testid="footer-instagram-link"
            href="http://www.instagram.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img data-testid="footer-instagram-image" src={INSTAGRAM_IMAGE} alt="instagram" />
          </a>
        </div>
      </footer>
    </>
  )
}
