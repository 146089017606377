import SATAY_SAUCE_FRONT_IMAGE from '@srcroot/assets/satay-sauce/satay-marinade-sauce-front-333x222.jpg'
import SATAY_SAUCE_LARGE_FRONT_IMAGE from '@srcroot/assets/satay-sauce/satay-marinade-sauce-front-1024x683.jpg'
import SATAY_SAUCE_SIDE_IMAGE from '@srcroot/assets/satay-sauce/satay-marinade-sauce-side-394x222.jpeg'
import SATAY_SAUCE_LARGE_SIDE_IMAGE from '@srcroot/assets/satay-sauce/satay-marinade-sauce-side.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const SataySauce = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="satay-sauce-header" className="uppercase">
        Satay Marinade and Satay Sauce
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: SATAY_SAUCE_LARGE_FRONT_IMAGE, title: 'satay sauce front' },
              { src: SATAY_SAUCE_LARGE_SIDE_IMAGE, title: 'satay sauce side' },
            ])
          }
        >
          <img
            data-testid="satay-sauce-front-image"
            src={SATAY_SAUCE_FRONT_IMAGE}
            alt="satay sauce front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: SATAY_SAUCE_LARGE_SIDE_IMAGE, title: 'satay sauce side' },
              { src: SATAY_SAUCE_LARGE_FRONT_IMAGE, title: 'satay sauce front' },
            ])
          }
        >
          <img
            data-testid="satay-sauce-side-image"
            src={SATAY_SAUCE_SIDE_IMAGE}
            alt="satay sauce side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        <strong>Chicken satay</strong> marinated with spices and lemongrass. Served with sweet and
        savoury <strong>satay sauce</strong> cooked with roasted peanut crushed coarsely. Scoop them
        all with diced cucumber
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Chicken thigh strips - 800 g - 1 kg</li>
        <li>Bamboo sticks</li>
      </ol>

      <h2 className="uppercase text-gray-500">Method - satay marinade</h2>
      <ol>
        <li>Rinse the chicken and pat dry with paper towels</li>
        <li>
          Marinade the chicken with the <strong>Paste</strong>. Wrap & set aside for{' '}
          <strong>1 hour</strong> in fridge
        </li>
        <li>
          Soak bamboo skewers for at least <strong>30 mins</strong>
        </li>
        <li>
          Make satay skewers by threading 3-4 pieces of marinated chicken onto a soaked bamboo
          skewer. Continue until all the chicken is done
        </li>
        <li>
          Preheat oven at <strong>220</strong> degrees celcius for <strong>5 mins</strong>. Arrange
          the satay skewers on the rack
        </li>
        <li>
          Grill the satay for <strong>10 mins</strong>, or until the surface turn slightly charred &
          inside is cooked through
        </li>
        <li>
          Turn the skewers over, brush some oil over the satay & continue grilling for another{' '}
          <strong>5 mins</strong>, browned & completely cooked
        </li>
        <li>
          Rest for <strong>5 mins</strong> & ready to serve with <strong>peanut satay sauce</strong>
        </li>
      </ol>

      <h2 className="uppercase text-gray-500">Method - peanut satay sauce</h2>
      <ol>
        <li>
          Heat up <strong>1/2 cup</strong> of olive oil in pot. Add the{' '}
          <strong>wet ingredients</strong> on medium heat & stir fry until red oil surfaces
        </li>
        <li>
          Add <strong>1 cup</strong> of water & stir constantly. Bring to boil
        </li>
        <li>Turn off the heat. Add peanut & mix well</li>
      </ol>

      <Footer />
    </>
  )
}
