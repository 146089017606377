import LAKSA_MEE_FRONT_IMAGE from '@srcroot/assets/laksa-mee/Laksa-Kari-Front-333x222.jpg'
import LAKSA_MEE_LARGE_FRONT_IMAGE from '@srcroot/assets/laksa-mee/Laksa-Kari-Front-1024x683.jpg'
import LAKSA_MEE_SIDE_IMAGE from '@srcroot/assets/laksa-mee/Laksa-kari-side-394x222.jpeg'
import LAKSA_MEE_LARGE_SIDE_IMAGE from '@srcroot/assets/laksa-mee/Laksa-kari-side.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const LaksaMee = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="laksa-mee-header" className="uppercase">
        Laksa Mee
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: LAKSA_MEE_LARGE_FRONT_IMAGE, title: 'laksa mee front' },
              { src: LAKSA_MEE_LARGE_SIDE_IMAGE, title: 'laksa mee side' },
            ])
          }
        >
          <img
            data-testid="laksa-mee-front-image"
            src={LAKSA_MEE_FRONT_IMAGE}
            alt="laksa mee front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: LAKSA_MEE_LARGE_SIDE_IMAGE, title: 'laksa mee side' },
              { src: LAKSA_MEE_LARGE_FRONT_IMAGE, title: 'laksa mee front' },
            ])
          }
        >
          <img data-testid="laksa-mee-side-image" src={LAKSA_MEE_SIDE_IMAGE} alt="laksa mee side" />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        <strong>Curry Laksa noodle</strong> - popular hawker&apos;s dish that is full of flavours.
        Creamy soup infused with coconut milk, fragrant spices and good balance of spiciness.
        Squeeze some lime juice and top up with fresh mint for the citrusy taste. Never fails to
        satisfy your <strong>appetite</strong>
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Yellow noodle (or spaghetti)</li>
        <li>Snake bean - 1 bunch</li>
        <li>Tofu puffs & fish cake (optional)</li>
        <li>Fresh peeled shrimp - 8 pieces</li>
        <li>Eggs - 2 medium</li>
        <li>Fresh mint leave for garnish</li>
        <li>Fresh lemon</li>
        <li>Fresh milk - 500 ml</li>
      </ol>

      <h2 className="uppercase text-gray-500">To cook Laksa stock</h2>
      <ol>
        <li>
          Heat up the <strong>1 cup</strong> of olive oil in pot. Add the{' '}
          <strong>wet ingredient</strong> & stir fry on medium heat until aromatic
        </li>
        <li>
          Add <strong>Spice</strong> & stir constantly on reduced heat until red oil surfaces. Add{' '}
          <strong>1.5L</strong> water & bring to boil
        </li>
        <li>
          Add <strong>500ml</strong> full cream milk, keep the stock on simmer
        </li>
      </ol>

      <h2 className="uppercase text-gray-500">To prepare Laksa</h2>
      <p>Boil until cook separately for all the ingredients. Drain & set aside</p>

      <ol>
        <li>Yellow noodle or spaghetti</li>
        <li>
          Snake bean - cut <strong>5cm</strong> length
        </li>
        <li>Tofu puffs & fish cake</li>
        <li>Fresh peeled shrimp</li>
        <li>
          Eggs - peeled and sliced <strong>1/2</strong>
        </li>
      </ol>

      <h2 className="uppercase text-gray-500">To serve</h2>
      <p className="text-green-600">
        Transfer the prepared ingredients into serving bowl. Use ladle to pour the laksa stock into
        bowl. Squeeze of fresh lemon. Garnish with fresh mint leave
      </p>

      <Footer />
    </>
  )
}
