import { Banner } from './components/banner'
import { NavigationRouter } from './components/navigation-router'

export const App = () => {
  return (
    <>
      <Banner />
      <NavigationRouter />
    </>
  )
}
