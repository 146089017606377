import { OurStory } from './ourStory'
import { OurMealKits } from './ourMealKits'
import { DeliciousFreshEasy } from './deliciousFreshEasy'
import { Footer } from '@srcroot/components/footer'

import LAKSA_IMAGE from '@srcroot/assets/Laksa-kari-side-2000x1200.jpeg'
import CHICKEN_KARI_IMAGE from '@srcroot/assets/chicken-kari-side.jpeg'

export const Home = () => {
  return (
    <>
      <OurStory />
      <img data-testid="home-laksa-kari-image" src={LAKSA_IMAGE} alt="laksa kari side" />

      <OurMealKits />
      <img data-testid="home-chicken-kari-image" src={CHICKEN_KARI_IMAGE} alt="chicken kari side" />

      <DeliciousFreshEasy />

      <Footer />
    </>
  )
}
