import GREEN_KARI_FRONT_IMAGE from '@srcroot/assets/green-kari/green-kari-front-385x427.jpeg'
import GREEN_KARI_LARGE_FRONT_IMAGE from '@srcroot/assets/green-kari/green-kari-front-923x1024.jpeg'
import GREEN_KARI_SIDE_IMAGE from '@srcroot/assets/green-kari/green-kari_side-342x427.jpeg'
import GREEN_KARI_LARGE_SIDE_IMAGE from '@srcroot/assets/green-kari/green-kari_side-820x1024.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const GreenKari = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="green-kari-header" className="uppercase">
        Green Kari
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: GREEN_KARI_LARGE_FRONT_IMAGE, title: 'green kari front' },
              { src: GREEN_KARI_LARGE_SIDE_IMAGE, title: 'green kari side' },
            ])
          }
        >
          <img
            data-testid="green-kari-front-image"
            src={GREEN_KARI_FRONT_IMAGE}
            alt="green kari front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: GREEN_KARI_LARGE_SIDE_IMAGE, title: 'green kari side' },
              { src: GREEN_KARI_LARGE_FRONT_IMAGE, title: 'green kari front' },
            ])
          }
        >
          <img
            data-testid="green-kari-side-image"
            src={GREEN_KARI_SIDE_IMAGE}
            alt="green kari side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Chicken diced - 800 g - 1 kg</li>
        <li>Olive oil - 1/2 cup</li>
        <li>Fresh mint for garnish</li>
        <li>Water - 1/2 cup</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice or bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat the olive oil in pot. Add the <strong>wet ingredient</strong> & stir fry until
          aromatic
        </li>
        <li>
          Add <strong>Spices</strong> & stir constantly on reduced heat until green oil surfaces
        </li>
        <li>
          Add meat & water. Increase the heat, stir well & bring to boil. Close the lid & simmer
          with low heat for <strong>1 hour</strong> or until the meat tender. Stir periodically to
          prevent burn
        </li>
        <li>
          Cook for another <strong>10 mins</strong> with lid open to thicken the gravy or until the
          meat is cooked. Stir periodically
        </li>
      </ol>

      <Footer />
    </>
  )
}
