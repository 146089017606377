import CHICKEN_KARI_FRONT_IMAGE from '@srcroot/assets/chicken-kari/Chicken-Kari-Front-view-341x227.jpg'
import CHICKEN_KARI_LARGE_FRONT_IMAGE from '@srcroot/assets/chicken-kari/Chicken-Kari-Front-view-1024x683.jpg'
import CHICKEN_KARI_SIDE_IMAGE from '@srcroot/assets/chicken-kari/chicken-kari-side-386x227.jpeg'
import CHICKEN_KARI_LARGE_SIDE_IMAGE from '@srcroot/assets/chicken-kari/chicken-kari-side.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const ChickenKari = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()
  return (
    <>
      <h1 data-testid="chicken-kari-header" className="uppercase">
        Chicken Kari
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: CHICKEN_KARI_LARGE_FRONT_IMAGE, title: 'chicken kari front' },
              { src: CHICKEN_KARI_LARGE_SIDE_IMAGE, title: 'chicken kari side' },
            ])
          }
        >
          <img
            data-testid="chicken-kari-front-image"
            src={CHICKEN_KARI_FRONT_IMAGE}
            alt="chicken kari front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: CHICKEN_KARI_LARGE_SIDE_IMAGE, title: 'chicken kari side' },
              { src: CHICKEN_KARI_LARGE_FRONT_IMAGE, title: 'chicken kari front' },
            ])
          }
        >
          <img
            data-testid="chicken-kari-side-image"
            src={CHICKEN_KARI_SIDE_IMAGE}
            alt="chicken kari side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        The exotic aroma of spices and local grown ingredients with intense fragrance of curry
        leaves make the gravy so rich. Highly addictive and remarkably <strong>scrumptious</strong>.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Chicken - 1 - 1.2 kg</li>
        <li>Olive oil - 1/2 cup</li>
        <li>Potato cut into quarters - 2 medium size</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice or bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat the olive oil in pot. Add the <strong>wet ingredient</strong> & stir fry until
          aromatic
        </li>
        <li>
          Add <strong>Spices</strong> & stir fry constantly on reduced heat until red oil surfaces
        </li>
        <li>
          Add meat, potatoes & water. Increase the heat, stir well & bring to boil. Close the lid &
          simmer with low heat for <strong>20 - 25 mins</strong> or until the meat tender. Stir
          periodically to prevent burn
        </li>
        <li>
          Cook for another <strong>10 mins</strong> with lid open to thicken the gravy or until the
          meat is cooked. Stir periodically. Serve with steamed rice
        </li>
      </ol>

      <Footer />
    </>
  )
}
