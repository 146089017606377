import SPICE_CHICKEN_HERBS_FRONT_IMAGE from '@srcroot/assets/spice-chicken-herbs/Roasted-Spiced-Chic-Herbs-_front-364x485.jpeg'
import SPICE_CHICKEN_HERBS_LARGE_FRONT_IMAGE from '@srcroot/assets/spice-chicken-herbs/Roasted-Spiced-Chic-Herbs-_front-768x1024.jpeg'
import SPICE_CHICKEN_HERBS_SIDE_IMAGE from '@srcroot/assets/spice-chicken-herbs/Roasted-Spiced-Chic-Herbs-side-2-363x485.jpeg'
import SPICE_CHICKEN_HERBS_LARGE_SIDE_IMAGE from '@srcroot/assets/spice-chicken-herbs/Roasted-Spiced-Chic-Herbs-side-2-768x1024.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const SpiceChickenHerbs = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="spice-chicken-herbs-header" className="uppercase">
        Roasted Spice Chicken Herbs
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: SPICE_CHICKEN_HERBS_LARGE_FRONT_IMAGE, title: 'spice chicken herbs front' },
              { src: SPICE_CHICKEN_HERBS_LARGE_SIDE_IMAGE, title: 'spice chicken herbs side' },
            ])
          }
        >
          <img
            data-testid="spice-chicken-herbs-front-image"
            src={SPICE_CHICKEN_HERBS_FRONT_IMAGE}
            alt="spice chicken herbs front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: SPICE_CHICKEN_HERBS_LARGE_SIDE_IMAGE, title: 'spice chicken herbs side' },
              { src: SPICE_CHICKEN_HERBS_LARGE_FRONT_IMAGE, title: 'spice chicken herbs front' },
            ])
          }
        >
          <img
            data-testid="spice-chicken-herbs-side-image"
            src={SPICE_CHICKEN_HERBS_SIDE_IMAGE}
            alt="spice chicken herbs side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        This is an <strong>easy</strong> and incredibly flavoursome way to prepare{' '}
        <strong>boneless skinless</strong> chicken breasts. An aromatic blend of sesame oil and
        spices. Even <strong>kids</strong> seem to love it.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Chicken Thigh Strip - 800g</li>
        <li>Pita wrap</li>
        <li>Cucumber in strips</li>
        <li>Sweet chili sauce</li>
      </ol>

      <p className="my-10 text-green-600">Serve with rice or pita bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>Rinse the chicken and pat dry with paper towels</li>
        <li>
          Marinade the chicken with the paste. Wrap & set aside for <strong>1 hour</strong> in
          fridge
        </li>
        <li>
          Pre-Heat the oven to <strong>220</strong> degrees celcius. Arrange the marinaded chicken
          on oven grill tray. Roast for <strong>30 - 40 mins</strong> until the surface turns
          slightly charred and inside is cooked through. Serve warm with wrap
        </li>
      </ol>

      <Footer />
    </>
  )
}
