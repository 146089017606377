import LAMB_KARI_FRONT_IMAGE from '@srcroot/assets/lamb-kari/lamb-kari-front-394x222.jpeg'
import LAMB_KARI_LARGE_FRONT_IMAGE from '@srcroot/assets/lamb-kari/lamb-kari-front.jpeg'
import LAMB_KARI_SIDE_IMAGE from '@srcroot/assets/lamb-kari/lamb-kari-side-view-333x222.jpg'
import LAMB_KARI_LARGE_SIDE_IMAGE from '@srcroot/assets/lamb-kari/lamb-kari-side-view-1024x683.jpg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const LambKari = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="lamb-kari-header" className="uppercase">
        Lamb Kari
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: LAMB_KARI_LARGE_FRONT_IMAGE, title: 'lamb kari front' },
              { src: LAMB_KARI_LARGE_SIDE_IMAGE, title: 'lamb kari side' },
            ])
          }
        >
          <img
            data-testid="lamb-kari-front-image"
            src={LAMB_KARI_FRONT_IMAGE}
            alt="lamb kari front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: LAMB_KARI_LARGE_SIDE_IMAGE, title: 'lamb kari side' },
              { src: LAMB_KARI_LARGE_FRONT_IMAGE, title: 'lamb kari front' },
            ])
          }
        >
          <img data-testid="lamb-kari-side-image" src={LAMB_KARI_SIDE_IMAGE} alt="lamb kari side" />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        The exotic aroma of spices and local grown ingredients with intense fragrance of curry
        leaves making the gravy so rich. Highly addictive and remarkably{' '}
        <strong>scrumptious</strong>.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Lamb diced 2cm x 2cm - 800g - 1kg</li>
        <li>Olive oil - 1/2 cup</li>
        <li>Potato cut into quarters - 2 small size</li>
        <li>Water - 1/2 cup</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice or bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat the olive oil in pot. Add the <strong>wet ingredient</strong> and stir fry until
          aromatic
        </li>
        <li>
          Add <strong>Spices</strong> and stir constantly on reduced heat until red oil surfaces
        </li>
        <li>
          Add meat, potatoes & water. Increase the heat, stir well & bring to boil. Close the lid &
          simmer with low heat for <strong>20 - 25 mins</strong> or until the meat tender. Stir
          periodically to prevent burn.
        </li>
        <li>
          Cook for another <strong>15 mins</strong> with lid open to thicken the gravy or until the
          meat is cooked. Stir periodically
        </li>
      </ol>

      <Footer />
    </>
  )
}
