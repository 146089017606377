import BEEF_RENDANG_FRONT_IMAGE from '@srcroot/assets/beef-rendang/beef-rendang-front-view-412x560.jpeg'
import BEEF_RENDANG_LARGE_FRONT_IMAGE from '@srcroot/assets/beef-rendang/beef-rendang-front-view-754x1024.jpeg'
import BEEF_RENDANG_SIDE_IMAGE from '@srcroot/assets/beef-rendang/beef-rendang-side-view-315x560.jpeg'
import BEEF_RENDANG_LARGE_SIDE_IMAGE from '@srcroot/assets/beef-rendang/beef-rendang-side-view-576x1024.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const BeefRendang = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="beef-rendang-header" className="uppercase">
        Beef Rendang
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: BEEF_RENDANG_LARGE_FRONT_IMAGE, title: 'beef rendang front' },
              { src: BEEF_RENDANG_LARGE_SIDE_IMAGE, title: 'beef rendang side' },
            ])
          }
        >
          <img
            data-testid="beef-rendang-front-image"
            src={BEEF_RENDANG_FRONT_IMAGE}
            alt="beef rendang front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: BEEF_RENDANG_LARGE_SIDE_IMAGE, title: 'beef rendang side' },
              { src: BEEF_RENDANG_LARGE_FRONT_IMAGE, title: 'beef rendang front' },
            ])
          }
        >
          <img
            data-testid="beef-rendang-side-image"
            src={BEEF_RENDANG_SIDE_IMAGE}
            alt="beef rendang side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        <strong>Rendang Beef</strong> - rich and tender beef stew which is explosively flavoursome.
        Spices, toasted coconut and fresh lemon grass are blended in perfectly. Stew over very low
        heat to thicken the gravy, making every chunk of beef packed with so much flavour. It gets
        even <strong>better overnight.</strong>
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Beef brisket diced 4cm x 4cm - 1kg</li>
        <li>Olive Oil - 1/2 cup</li>
        <li>Water - 1 cup</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat up oil in pot. Add the <strong>wet ingredient</strong> and stir fry on medium heat
          until aromatic
        </li>
        <li>
          Add <strong>Spices</strong> and stir constantly on reduced heat until red oil surfaces
        </li>
        <li>
          Add meat & water. Increase the heat, stir well & bring to boil. Close the lid & simmer
          with low heat for <strong>1 hour</strong> or until the meat tender. Stir periodically to
          prevent burn
        </li>
        <li>
          Add desiccated coconut. Stew over very low heat with the lid open for another{' '}
          <strong>1 hour</strong> or until the gravy thickens. Stir periodically to prevent burn. If
          the gravy gets too dry, add some water & stew further
        </li>
      </ol>

      <Footer />
    </>
  )
}
