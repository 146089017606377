import { Footer } from '@srcroot/components/footer'

export const ContactUs = () => {
  return (
    <>
      <h1 data-testid="contact-us-header" className="uppercase">
        Contact Us
      </h1>

      <div className="text-center">
        <p>Mobile: 0435 062 180</p>
        <p>
          Email:{' '}
          <a href="mailto:treeaprons@gmail.com" target="_blank" rel="noreferrer">
            treeaprons@gmail.com
          </a>
        </p>
      </div>

      <h2 className="uppercase text-gray-500">How to purchase?</h2>
      <p>Please call or email and we can discuss pickup or delivery arrangement. Thank you.</p>

      <Footer />
    </>
  )
}
