import NYONYA_CHICKEN_FRONT_IMAGE from '@srcroot/assets/nyonya-chicken/Nyonya-kari-noddle-364x242.jpg'
import NYONYA_CHICKEN_LARGE_FRONT_IMAGE from '@srcroot/assets/nyonya-chicken/Nyonya-kari-noddle-1024x683.jpg'
import NYONYA_CHICKEN_SIDE_IMAGE from '@srcroot/assets/nyonya-chicken/Nyonya-Kari-Bread-363x242.jpg'
import NYONYA_CHICKEN_LARGE_SIDE_IMAGE from '@srcroot/assets/nyonya-chicken/Nyonya-Kari-Bread-1024x683.jpg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const NyonyaChicken = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="nyonya-chicken-header" className="uppercase">
        Nyonya Chicken
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: NYONYA_CHICKEN_LARGE_FRONT_IMAGE, title: 'nyonya chicken side' },
              { src: NYONYA_CHICKEN_LARGE_SIDE_IMAGE, title: 'nyonya chicken front' },
            ])
          }
        >
          <img
            data-testid="nyonya-chicken-front-image"
            src={NYONYA_CHICKEN_FRONT_IMAGE}
            alt="nyonya chicken front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: NYONYA_CHICKEN_LARGE_SIDE_IMAGE, title: 'nyonya chicken front' },
              { src: NYONYA_CHICKEN_LARGE_FRONT_IMAGE, title: 'nyonya chicken side' },
            ])
          }
        >
          <img
            data-testid="nyonya-chicken-side-image"
            src={NYONYA_CHICKEN_SIDE_IMAGE}
            alt="nyonya chicken side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        Chili oil, spices, curry leaves and lemongrass. A <strong>simple</strong> dish yet full of
        aroma. Just add onto boiled spaghetti or white rice. Tastes just as good with bread.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Diced chicken - 800 g - 1 kg</li>
        <li>Olive oil - 1/2 cup</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice or bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat olive oil in pot. Add <strong>wet ingredients</strong> and stir fry on medium heat
          until aromatic
        </li>
        <li>
          Add <strong>Spices</strong> & stir constantly on reduced heat until red oil surfaces
        </li>
        <li>
          Add meat & stir well. Close the lid & simmer with low heat for <strong>15 mins</strong>.
          Stir periodically to prevent burn
        </li>
        <li>
          Cook with low fire for another <strong>15 - 20 mins</strong> with lid open to thicken the
          gravy. Stir periodically to prevent burn. Serve with steamed rice or bread.
        </li>
      </ol>

      <Footer />
    </>
  )
}
