import FISH_KARI_FRONT_IMAGE from '@srcroot/assets/fish-kari/Fish-Kari-front-364x242.jpg'
import FISH_KARI_LARGE_FRONT_IMAGE from '@srcroot/assets/fish-kari/Fish-Kari-front-1024x683.jpg'
import FISH_KARI_SIDE_IMAGE from '@srcroot/assets/fish-kari/Fish-Kari-side-363x242.jpg'
import FISH_KARI_LARGE_SIDE_IMAGE from '@srcroot/assets/fish-kari/Fish-Kari-side-1024x683.jpg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const FishKari = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="fish-kari-header" className="uppercase">
        Fish Kari
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: FISH_KARI_LARGE_FRONT_IMAGE, title: 'fish kari front' },
              { src: FISH_KARI_LARGE_SIDE_IMAGE, title: 'fish kari side' },
            ])
          }
        >
          <img
            data-testid="fish-kari-front-image"
            src={FISH_KARI_FRONT_IMAGE}
            alt="fish kari front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              { src: FISH_KARI_LARGE_SIDE_IMAGE, title: 'fish kari side' },
              { src: FISH_KARI_LARGE_FRONT_IMAGE, title: 'fish kari front' },
            ])
          }
        >
          <img data-testid="fish-kari-side-image" src={FISH_KARI_SIDE_IMAGE} alt="fish kari side" />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        <strong>Spicy aroma</strong> fills the kitchen and attracts the attention of curious, hungry
        neighbors. Simmer to absorb all the flavour and swirls in a few spoonfuls of sweet and rich
        coconut milk.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Mackerel Fish - 1 - 1.2 kg</li>
        <li>Olive oil - 1/2 cup</li>
        <li>Tomato - 1 med, cut into quarters</li>
        <li>Okra, trimmed stalk 5 - 7 pieces</li>
        <li>Fresh mint for garnish</li>
        <li>Water - 1/2 cup</li>
      </ol>

      <p className="my-10 text-green-600">Serve with steamed rice or bread</p>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat up the olive oil in pot. Add the <strong>wet ingredients</strong> & stir fry on
          medium heat until aromatic
        </li>
        <li>
          Add <strong>Spice</strong> & stir constantly on reduced heat until red oil surfaces. Add
          the cut tomato, okra & water
        </li>
        <li>
          Close the lid & bring to boil for <strong>5 mins</strong> or untl the tomato & okra are
          softened. Remove the okra to prevent over cook
        </li>
        <li>
          Add the fish, cook with medium heat for another <strong>4 - 5 mins</strong> with lid open
          to thicken the gravy. Stir gently to ensure the fish is intact. Don&apos;t forget to add
          the cooked okra back into pot. Garnish with fresh mint & serve with steamed rice
        </li>
      </ol>

      <Footer />
    </>
  )
}
