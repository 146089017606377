import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

export const ImagesLightbox = ({ open, closeFn, images }) => {
  return (
    <Lightbox
      styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .8)' } }}
      open={open}
      close={closeFn}
      slides={images}
      plugins={[Captions, Zoom]}
    />
  )
}
