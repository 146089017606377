import BEEF_RENDANG_MEAL_KIT_IMAGE from '@srcroot/assets/beef-rendang/beef-rendangr2.png'
import CHICKEN_KARI_MEAL_KIT_IMAGE from '@srcroot/assets/chicken-kari/Chicken-karir2.png'
import NYONYA_CHICKEN_MEAL_KIT_IMAGE from '@srcroot/assets/nyonya-chicken/nyonya-chickenr2.png'

import { Link } from 'react-router-dom'

export const OurMealKits = () => {
  return (
    <>
      <h1 className="uppercase">Our Meal Kits</h1>
      <p>All the ingredients (except meat / fish) you’ll need to cook your tasty meal.</p>
      <p className="text-center">
        Click <Link to="products">here</Link> to see more of our products
      </p>

      <div className="flex justify-evenly">
        <Link to="products/beefRendang">
          <img
            data-testid="home-beef-rendang-meal-kit-image"
            src={BEEF_RENDANG_MEAL_KIT_IMAGE}
            alt="beef rendang meal kit"
          />
          <p className="text-center">beef rendang</p>
        </Link>
        <Link to="products/chickenKari">
          <img
            data-testid="home-chicken-kari-meal-kit-image"
            src={CHICKEN_KARI_MEAL_KIT_IMAGE}
            alt="chicken kari meal kit"
          />
          <p className="text-center">chicken kari</p>
        </Link>
        <Link to="products/nyonyaChicken">
          <img
            data-testid="home-nyonya-chicken-meal-kit-image"
            src={NYONYA_CHICKEN_MEAL_KIT_IMAGE}
            alt="nyonya chicken meal kit"
          />
          <p className="text-center">nyonya chicken</p>
        </Link>
      </div>
    </>
  )
}
