import SPICE_CHICKEN_COCONUT_FRONT_IMAGE from '@srcroot/assets/spice-chicken-coconut/Roasted-Spiced-Chic-Coconut-front-303x270.jpeg'
import SPICE_CHICKEN_COCONUT_LARGE_FRONT_IMAGE from '@srcroot/assets/spice-chicken-coconut/Roasted-Spiced-Chic-Coconut-front-1024x915.jpeg'
import SPICE_CHICKEN_COCONUT_SIDE_IMAGE from '@srcroot/assets/spice-chicken-coconut/Roasted-Spiced-Chic-Coconut-side-424x270.jpeg'
import SPICE_CHICKEN_COCONUT_LARGE_SIDE_IMAGE from '@srcroot/assets/spice-chicken-coconut/Roasted-Spiced-Chic-Coconut-side.jpeg'

import { Footer } from '@srcroot/components/footer'

import { ImagesLightbox } from '@srcroot/components/images-lightbox'
import { useImagesLightbox } from '@srcroot/components/images-lightbox/useImagesLightbox'

export const SpiceChickenCoconut = () => {
  const { open, setOpen, images, openLightboxAndSetImages } = useImagesLightbox()

  return (
    <>
      <h1 data-testid="spice-chicken-coconut-header" className="uppercase">
        Roasted Spice Chicken Coconut
      </h1>

      <div className="flex justify-evenly mb-8">
        <button
          onClick={() =>
            openLightboxAndSetImages([
              {
                src: SPICE_CHICKEN_COCONUT_LARGE_FRONT_IMAGE,
                title: 'spice chicken coconut front',
              },
              {
                src: SPICE_CHICKEN_COCONUT_LARGE_SIDE_IMAGE,
                title: 'spice chicken coconut side',
              },
            ])
          }
        >
          <img
            data-testid="spice-chicken-coconut-front-image"
            src={SPICE_CHICKEN_COCONUT_FRONT_IMAGE}
            alt="spice chicken coconut front"
          />
        </button>
        <button
          onClick={() =>
            openLightboxAndSetImages([
              {
                src: SPICE_CHICKEN_COCONUT_LARGE_SIDE_IMAGE,
                title: 'spice chicken coconut side',
              },
              {
                src: SPICE_CHICKEN_COCONUT_LARGE_FRONT_IMAGE,
                title: 'spice chicken coconut front',
              },
            ])
          }
        >
          <img
            data-testid="spice-chicken-coconut-side-image"
            src={SPICE_CHICKEN_COCONUT_SIDE_IMAGE}
            alt="spice chicken coconut side"
          />
        </button>
      </div>

      <ImagesLightbox open={open} closeFn={() => setOpen(false)} images={images} />

      <p>
        An aromatic blend of local fresh produce. Packed with so much flavour from spices and
        coconut gravy. Mildly spicy with chili, even <strong>kids</strong> seem to love it.
      </p>

      <h2 className="uppercase text-gray-500">You need</h2>
      <ol>
        <li>Medium chicken maryland - 2 pcs</li>
        <li>Olive oil - 1/2 cup</li>
      </ol>

      <h2 className="uppercase text-gray-500">Method</h2>
      <ol>
        <li>
          Heat the olive oil in pot. Add the <strong>wet ingredient</strong> and stir fry until
          aromatic. About <strong>5 - 6 mins</strong>
        </li>
        <li>
          Add <strong>Spices</strong> and stir constantly on medium heat until red oil surfaces
        </li>
        <li>Add meat & stir fry until the meat half cook. Turn off the heat.</li>
        <li>
          Pre heat the oven at <strong>220</strong> degrees celcius. Transfer the half cooked
          chicken to the oven tray, roast for about <strong>30 - 40 mins</strong> or until the meat
          is slightly charred
        </li>
      </ol>

      <Footer />
    </>
  )
}
