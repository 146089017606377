export const DeliciousFreshEasy = () => {
  return (
    <>
      <h1 className="uppercase">Delicious | Fresh | Easy</h1>
      <div className="text-center">
        <p>Australian fresh produce</p>
        <p>Easy to cook</p>
        <p>No added MSG</p>
      </div>
    </>
  )
}
